import Cookies from 'js-cookie'

export const state = () => ({
  show: false
})

export const getters = {
  seen: (state, getters, rootState) => Cookies.getJSON('cookiesAccept'),
  show: state => state.show
}

export const mutations = {
  MARK_AS_SEEN (state, payload) {
    Cookies.set('cookiesAccept', true, { expires: 90 })
  },
  SHOW (state, payload) {
    state.show = payload
  }
}

export const actions = {
  show ({ commit }) {
    commit('SHOW', true)
  },
  hide ({ commit }) {
    commit('SHOW', false)
    commit('MARK_AS_SEEN', true)
  }
}
