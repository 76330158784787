import Cookies from 'js-cookie'

export const state = () => ({
  cpf: false,
  open: false,
  position: false,
  redirected: Cookies.getJSON('redirected')
})

export const mutations = {
  cpf (state, payload) {
    state.cpf = payload
  },
  open (state, payload) {
    state.open = payload
  },
  position (state, payload) {
    state.position = payload
  },
  redirected (state, payload) {
    state.redirected = payload
    if (payload) {
      Cookies.set('redirected', payload)
    } else {
      Cookies.remove('redirected')
    }
  }
}
