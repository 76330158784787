module.exports = {
  // for website
  primary: '#FFC600',
  secondary: '#EA0029',
  anchor: '#fff',
  error: '#D50000',
  success: '#2E7D32',
  warning: '#FF8F00',

  // for defaults
  darkg: '#37474F', // blue-grey darken-3
  lightg: '#cbd5e1', // slate-300
  simpleg: '#607D8B', // blue-grey

  // for dashboard
  dmain: '#0277BD',
  dinfo: '#0097a7',
  daccent: '#FF6F00'
}
