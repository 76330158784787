import Cookies from 'js-cookie'

export const state = () => ({
  user: Cookies.getJSON(`auth.${state.strategy}.user`),
  token: Cookies.getJSON(`auth.${state.strategy}.token`),
  redirected: Cookies.getJSON(`auth.${state.strategy}.redirected`),
  strategy: 'user'
})

export const getters = {
  redirected: state => state.redirected,
  token: state => state.token,
  user: state => state.user,
  can: (state, getters) => (permission, action) => getters.permissions.some(p => p.permission === permission && p.action === action),
  permissions: state => state.user ? state.user.permissions : []
}

export const mutations = {
  STRATEGY (state, payload) {
    state.strategy = payload
    state.user = Cookies.getJSON(`auth.${state.strategy}.user`)
    state.token = Cookies.getJSON(`auth.${state.strategy}.token`)
    state.redirected = Cookies.getJSON(`auth.${state.strategy}.redirected`)
  },
  USER (state, payload) {
    const cookie = `auth.${state.strategy}.user`

    if (payload) {
      Cookies.set(cookie, payload)
    } else {
      Cookies.remove(cookie)
    }

    state.user = payload
  },
  TOKEN (state, payload) {
    const cookie = `auth.${state.strategy}.token`

    if (payload) {
      Cookies.set(cookie, payload)
    } else {
      Cookies.remove(cookie)
    }

    state.token = payload
  },
  REDIRECTED (state, payload) {
    const cookie = `auth.${state.strategy}.redirected`

    if (payload) {
      Cookies.set(cookie, payload)
    } else {
      Cookies.remove(cookie)
    }

    state.redirected = payload
  }
}

export const actions = {
  fetchUser ({ state, getters, commit }, payload) {
    const force = payload && payload.force
    return new Promise((resolve, reject) => {
      if (!getters.token || (getters.user && !force)) {
        return resolve()
      }
      commit('USER', null)
      this.$axios.$get(this.$auth.endpoints(state.strategy).user).then((data) => {
        commit('USER', data)
        resolve()
      }).catch(() => {
        commit('TOKEN', null)
        resolve()
      })
    })
  },
  login ({ state, getters, commit }, payload) {
    return this.$axios.$post(this.$auth.endpoints(state.strategy).login, payload).then((data) => {
      let path = { name: this.$auth.routes(state.strategy).home }

      if (state.redirected) {
        path = state.redirected
        commit('REDIRECTED')
      }

      commit('TOKEN', data.token)
      this.$router.push(path)
    })
  },
  logout ({ state, getters, commit }) {
    commit('USER', null)

    return new Promise((resolve, reject) => {
      if (!getters.token) {
        return resolve()
      }
      this.$axios.$post(this.$auth.endpoints(state.strategy).logout).catch(() => {}).finally(() => {
        commit('TOKEN', null)
        this.$router.push({ name: this.$auth.routes(state.strategy).logout })
      })
    })
  }
}
