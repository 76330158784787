const { default: pt } = require('vuetify/es5/locale/pt')
const { default: en } = require('vuetify/es5/locale/en')

module.exports = (theme) => ({
  theme: {
    dark: false,
    themes: { light: theme.colors },
    options: { customProperties: true, variations: false }
  },
  icons: { iconfont: 'mdiSvg', values: theme.icons.vuetify },
  lang: { locales: { en, pt }, current: 'pt' },
  treeShake: true,
  defaultAssets: false,
  customVariables: ['~assets/variables.scss']
})
