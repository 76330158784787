import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6d728bee = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _31a3b878 = () => interopDefault(import('../pages/cupom.vue' /* webpackChunkName: "pages/cupom" */))
const _0f577ef2 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _166703a0 = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _34fa65ac = () => interopDefault(import('../pages/regulamentos.vue' /* webpackChunkName: "pages/regulamentos" */))
const _2a92ec68 = () => interopDefault(import('../pages/vencedores.vue' /* webpackChunkName: "pages/vencedores" */))
const _19c97d5c = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _fb898936 = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _28cf1ac2 = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _729da3b8 = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _2f618aa0 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _1e680bbf = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _acc6a632 = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _8a3fa5c4 = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _66cd1635 = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _488b959f = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _a19fa506 = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _60ef806a = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _33f36bba = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _567d3ff9 = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _85e70530 = () => interopDefault(import('../pages/admin/regulations/index.vue' /* webpackChunkName: "pages/admin/regulations/index" */))
const _5c340337 = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _09054ec6 = () => interopDefault(import('../pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _22384e31 = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _746e2506 = () => interopDefault(import('../pages/admin/total-tickets/index.vue' /* webpackChunkName: "pages/admin/total-tickets/index" */))
const _5da4c1a7 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _525e7734 = () => interopDefault(import('../pages/admin/winners-draw/index.vue' /* webpackChunkName: "pages/admin/winners-draw/index" */))
const _e98c4174 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _7d812516 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _53e857f4 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _feaa44f4 = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _32d75ed6 = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _53649242 = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _9ca58a92 = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _caec09ae = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _0c377a56 = () => interopDefault(import('../pages/admin/regulations/create.vue' /* webpackChunkName: "pages/admin/regulations/create" */))
const _4c85c2ad = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _7ea7bdf7 = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _2124ba0a = () => interopDefault(import('../pages/admin/winners-draw/create.vue' /* webpackChunkName: "pages/admin/winners-draw/create" */))
const _7ee68c5d = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _42427ea0 = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _038d2ca2 = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _8050d6e2 = () => interopDefault(import('../pages/admin/regulations/edit/_id.vue' /* webpackChunkName: "pages/admin/regulations/edit/_id" */))
const _574573f0 = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _4e73b743 = () => interopDefault(import('../pages/admin/winners-draw/edit/_id.vue' /* webpackChunkName: "pages/admin/winners-draw/edit/_id" */))
const _1de1b278 = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _0bb3f1e7 = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _66f56b25 = () => interopDefault(import('../pages/admin/invoices/_id.vue' /* webpackChunkName: "pages/admin/invoices/_id" */))
const _785a5a02 = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _3a1ceca1 = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _95c583c6 = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))
const _3bbf3458 = () => interopDefault(import('../pages/unsubscribe/_id.vue' /* webpackChunkName: "pages/unsubscribe/_id" */))
const _48d5ddae = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _6d728bee,
    name: "admin"
  }, {
    path: "/cupom",
    component: _31a3b878,
    name: "cupom"
  }, {
    path: "/faq",
    component: _0f577ef2,
    name: "faq"
  }, {
    path: "/perfil",
    component: _166703a0,
    name: "perfil"
  }, {
    path: "/regulamentos",
    component: _34fa65ac,
    name: "regulamentos"
  }, {
    path: "/vencedores",
    component: _2a92ec68,
    name: "vencedores"
  }, {
    path: "/admin/actions",
    component: _19c97d5c,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _fb898936,
    name: "admin-calculation"
  }, {
    path: "/admin/commands",
    component: _28cf1ac2,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _729da3b8,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _2f618aa0,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _1e680bbf,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _acc6a632,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _8a3fa5c4,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _66cd1635,
    name: "admin-faq"
  }, {
    path: "/admin/indexes",
    component: _488b959f,
    name: "admin-indexes"
  }, {
    path: "/admin/invoices",
    component: _a19fa506,
    name: "admin-invoices"
  }, {
    path: "/admin/ips",
    component: _60ef806a,
    name: "admin-ips"
  }, {
    path: "/admin/permissions",
    component: _33f36bba,
    name: "admin-permissions"
  }, {
    path: "/admin/registrations",
    component: _567d3ff9,
    name: "admin-registrations"
  }, {
    path: "/admin/regulations",
    component: _85e70530,
    name: "admin-regulations"
  }, {
    path: "/admin/statistics",
    component: _5c340337,
    name: "admin-statistics"
  }, {
    path: "/admin/tickets",
    component: _09054ec6,
    name: "admin-tickets"
  }, {
    path: "/admin/tokens",
    component: _22384e31,
    name: "admin-tokens"
  }, {
    path: "/admin/total-tickets",
    component: _746e2506,
    name: "admin-total-tickets"
  }, {
    path: "/admin/users",
    component: _5da4c1a7,
    name: "admin-users"
  }, {
    path: "/admin/winners-draw",
    component: _525e7734,
    name: "admin-winners-draw"
  }, {
    path: "/forgot",
    component: _e98c4174,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _7d812516,
    name: "auth-login"
  }, {
    path: "/register",
    component: _53e857f4,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _feaa44f4,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _32d75ed6,
    name: "admin-auth-login"
  }, {
    path: "/admin/dispatches/create",
    component: _53649242,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/email-templates/create",
    component: _9ca58a92,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _caec09ae,
    name: "admin-faq-create"
  }, {
    path: "/admin/regulations/create",
    component: _0c377a56,
    name: "admin-regulations-create"
  }, {
    path: "/admin/tokens/create",
    component: _4c85c2ad,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _7ea7bdf7,
    name: "admin-users-create"
  }, {
    path: "/admin/winners-draw/create",
    component: _2124ba0a,
    name: "admin-winners-draw-create"
  }, {
    path: "/admin/reset/:token?",
    component: _7ee68c5d,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _42427ea0,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _038d2ca2,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/regulations/edit/:id?",
    component: _8050d6e2,
    name: "admin-regulations-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _574573f0,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/winners-draw/edit/:id",
    component: _4e73b743,
    name: "admin-winners-draw-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _1de1b278,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _0bb3f1e7,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/invoices/:id",
    component: _66f56b25,
    name: "admin-invoices-id"
  }, {
    path: "/admin/ips/:ip",
    component: _785a5a02,
    name: "admin-ips-ip"
  }, {
    path: "/admin/registrations/:id",
    component: _3a1ceca1,
    name: "admin-registrations-id"
  }, {
    path: "/reset/:token?",
    component: _95c583c6,
    name: "auth-reset-token"
  }, {
    path: "/unsubscribe/:id?",
    component: _3bbf3458,
    name: "unsubscribe-id"
  }, {
    path: "/",
    component: _48d5ddae,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
